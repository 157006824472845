<template>
  <div class="d_flex">
    <!-- Start: Filter area -->
    <div class="px-5">
      <filter-area
        :date-filter="dateFilter"
        :employees-filter="authorsFilter"
        :site-filter="siteFilter"
        :status-filter="statusFilter"
        @updateFilterProperty="updateFilterProperty($event)"
      ></filter-area>
    </div>
    <!-- End: Filter area -->
    <!-- Start: Main area -->
    <div class="flex-grow-1 overflow-hidden">
      <!-- Start: Top actions -->
      <top-action
        :current-page="currentPage"
        :search-key="searchKey"
        :selected-show-filter-id="itemPerPage"
        @updateCurrentPage="updateCurrentPage($event)"
        @updateItemPerPage="updateItemPerPage($event)"
        @updateSearchKey="updateSearchKey($event)"
      ></top-action>
      <!-- End: Top actions -->
      <!-- Start: Data table -->
      <div class="mr-5">
        <data-table-items></data-table-items>
      </div>
      <!-- End: Data table -->
    </div>
    <!-- End: Main area -->
  </div>
</template>

<script>
import DataTableItems from "./components/DataTableItems";
import FilterArea from "./components/FilterArea";
import TopAction from "./components/TopAction";
import { updateFilterProperty } from "@/core/composables";

export default {
  components: {
    DataTableItems,
    FilterArea,
    TopAction
  },
  data() {
    return {
      authorsFilter: [],
      currentPage: 1,
      dateFilter: {
        value: null,
        type: 1
      },
      itemPerPage: 50,
      searchKey: null,
      siteFilter: "ALL",
      statusFilter: null
    };
  },
  watch: {
    "$route.query"(val) {
      this.getPosts(val);
    }
  },
  async created() {
    const route = this.$route;

    await this.getPosts(route.query);
  },
  methods: {
    pushRouter() {
      this.$router.push({
        name: "website_posts",
        query: {
          created_by_id:
            this.authorsFilter && this.authorsFilter.length > 0
              ? this.authorsFilter
              : undefined,
          status:
            (this.statusFilter && this.statusFilter !== "all") ||
            this.statusFilter === 0
              ? this.statusFilter
              : undefined,
          date_type:
            this.dateFilter.value &&
            this.dateFilter.value[0] &&
            this.dateFilter.type
              ? this.dateFilter.type
              : undefined,
          date_from:
            this.dateFilter && this.dateFilter.value
              ? this.dateFilter.value[0]
              : undefined,
          date_to:
            this.dateFilter && this.dateFilter.value
              ? this.dateFilter.value[1]
              : undefined,
          site: this.siteFilter,
          search:
            this.searchKey && this.searchKey.length > 0
              ? this.searchKey
              : undefined,
          limit: this.itemPerPage,
          page: this.currentPage
        }
      });
    },

    async getPosts(query) {
      if (query.page) {
        // Set current page
        this.currentPage = parseInt(query.page);
        // Set filtered date
        this.dateFilter = query.date_from
          ? {
              value: [query.date_from, query.date_to],
              type: parseInt(query.date_type)
            }
          : { value: null, type: 1 };
        // Set filtered authors
        this.authorsFilter =
          typeof query.created_by_id === "string"
            ? [parseInt(query.created_by_id)]
            : typeof query.created_by_id === "object"
            ? query.created_by_id.map(item => parseInt(item))
            : [];
        // Set item per page
        this.itemPerPage = parseInt(query.limit);
        // Set search key
        this.searchKey = query.search || null;

        this.siteFilter = query.site || "ALL";

        // Set filtered status
        this.statusFilter =
          query.status || query.status === 0 ? parseInt(query.status) : "all";

        // Get products
        await this.$store.dispatch("POST/getPosts", {
          created_by: this.authorsFilter,
          status:
            (this.statusFilter && this.statusFilter !== "all") ||
            this.statusFilter === 0
              ? this.statusFilter
              : null,
          start_date:
            this.dateFilter && this.dateFilter.value
              ? this.dateFilter.value[0]
              : null,
          end_date:
            this.dateFilter && this.dateFilter.value
              ? this.dateFilter.value[1]
              : null,
          site: this.siteFilter,
          search: this.searchKey,
          per_page: this.itemPerPage,
          cur_page: this.currentPage
        });
      } else {
        // Push router query
        await this.pushRouter();
      }
    },

    updateCurrentPage(page) {
      if (this.currentPage === page) return;
      this.currentPage = page;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateFilterProperty(data) {
      updateFilterProperty(this, data.filterProperty, data.value);
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateItemPerPage(num) {
      if (this.itemPerPage === num) return;
      this.itemPerPage = num;
      this.currentPage = 1;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateSearchKey(val) {
      this.searchKey = val.searchKey;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    }
  }
};
</script>

<style lang="scss" scoped></style>
