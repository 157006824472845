<template>
  <div class="filter-area">
    <div class="font-weight-bold text-h5 mb-4">Danh sách bài viết</div>
    <v-card class="tp-filter-scroll pa-5" flat>
      <div class="">
        <div class="font-weight-bold mb-2">Ngày viết</div>
        <tp-input-time-filter
          :type="dateFilter.type"
          :value="dateFilter.value"
          @change="updateDateFilter($event)"
        ></tp-input-time-filter>
      </div>

      <div class="mt-5">
        <div class="font-weight-bold mb-2">Tác giả</div>
        <v-autocomplete
          v-model="selectedAuthors"
          class="tp-filter-autocomplete"
          :items="authors"
          dense
          deletable-chips
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          no-data-text="Không có dữ liệu"
          outlined
          chips
          single-line
          small-chips
          multiple
          placeholder="Chọn nhân viên"
        ></v-autocomplete>
      </div>

      <div class="mt-5">
        <div class="font-weight-bold mb-2">Trạng thái</div>
        <v-radio-group v-model="selectedStatus" class="mt-0" dense hide-details>
          <v-radio label="Tất cả" value="all"></v-radio>
          <v-radio label="Chờ duyệt" :value="0"></v-radio>
          <v-radio label="Đã duyệt" :value="1"></v-radio>
          <v-radio label="Bị ẩn" :value="-1"></v-radio>
        </v-radio-group>
      </div>

      <div class="mt-5">
        <div class="font-weight-bold mb-2">
          Hiển thị trên website
        </div>
        <v-radio-group v-model="selectedSite" class="mt-0" dense hide-details>
          <v-radio label="Tất cả" value="ALL"></v-radio>
          <v-radio
            v-for="(site, index) in ARRAY_LIST_MODEL_SITES"
            :key="index"
            :label="site.text"
            :value="site.id"
          ></v-radio>
          <v-radio label="Bị ẩn" value="NONE"></v-radio>
        </v-radio-group>
      </div>
    </v-card>
  </div>
</template>

<script>
import { ARRAY_LIST_MODEL_SITES, LIST_MODEL_SITES } from "@/core/constant";

export default {
  props: {
    authorsFilter: {
      type: Array
    },
    dateFilter: {
      type: Object
    },
    siteFilter: {
      type: String
    },
    statusFilter: {
      type: [Number, String]
    }
  },
  data() {
    return { ARRAY_LIST_MODEL_SITES, LIST_MODEL_SITES };
  },
  computed: {
    authors() {
      return this.$store.getters["EMPLOYEE/allEmployees"];
    },

    selectedAuthors: {
      get() {
        return this.authorsFilter;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "authorsFilter",
          value: val
        });
      }
    },

    selectedSite: {
      get() {
        return this.siteFilter;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "siteFilter",
          value: val
        });
      }
    },

    selectedStatus: {
      get() {
        return this.statusFilter;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "statusFilter",
          value: val
        });
      }
    }
  },

  async created() {
    if (this.authors.length === 0) {
      await this.$store.dispatch("EMPLOYEE/getAllEmployees");
    }
  },

  methods: {
    updateDateFilter(val) {
      this.$emit("updateFilterProperty", {
        filterProperty: "dateFilter",
        value: val
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-area {
  width: 256px;
}
</style>
