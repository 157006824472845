var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"tp-table-scroll tp-table__row-pointer datatable px-3 py-2",attrs:{"calculate-widths":"","disable-pagination":"","no-data-text":"Không có dữ liệu","no-results-text":"Không tìm thấy kết quả phù hợp","headers":_vm.headers,"hide-default-footer":"","loading":_vm.postStatusRequest.value === 'loading-getPosts',"loading-text":"Đang tải dữ liệu","items":_vm.posts,"item-key":"id"},on:{"click:row":function($event){return _vm.goToDetailPage($event)}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":item.status === 1
          ? 'green'
          : item.status === 0
          ? 'amber'
          : 'red accent-2',"small":"","outlined":""}},[_vm._v(" "+_vm._s(item.status === 1 ? "Đã duyệt" : item.status === 0 ? "Chờ duyệt" : "Bị ẩn")+" ")])]}},{key:"item.slug",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"ml-4 mr-2",attrs:{"color":"primary","text":"","link":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openNewTab(item)}}},[_vm._v(" Truy cập ")]),_c('v-btn',{attrs:{"color":"primary","text":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.$modal.show({
            name: 'modal-utm-builder',
            payload: (_vm.thinkproFrontStoreUrl + "/noi-dung/" + (item.slug))
          })}}},[_vm._v(" UTM Builder ")])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }