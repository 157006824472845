<template>
  <v-data-table
    class="tp-table-scroll tp-table__row-pointer datatable px-3 py-2"
    calculate-widths
    disable-pagination
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    :headers="headers"
    hide-default-footer
    :loading="postStatusRequest.value === 'loading-getPosts'"
    loading-text="Đang tải dữ liệu"
    :items="posts"
    item-key="id"
    @click:row="goToDetailPage($event)"
  >
    <template v-slot:[`item.status`]="{ item }">
      <v-chip
        class="font-weight-bold"
        :color="
          item.status === 1
            ? 'green'
            : item.status === 0
            ? 'amber'
            : 'red accent-2'
        "
        small
        outlined
      >
        {{
          item.status === 1
            ? "Đã duyệt"
            : item.status === 0
            ? "Chờ duyệt"
            : "Bị ẩn"
        }}
      </v-chip>
    </template>

    <template v-slot:[`item.slug`]="{ item }">
      <div class="d-flex align-center">
        <v-btn
          class="ml-4 mr-2"
          color="primary"
          text
          link
          small
          @click.stop="openNewTab(item)"
        >
          Truy cập
        </v-btn>

        <v-btn
          color="primary"
          text
          small
          @click.stop="
            $modal.show({
              name: 'modal-utm-builder',
              payload: `${thinkproFrontStoreUrl}/noi-dung/${item.slug}`
            })
          "
        >
          UTM Builder
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "Tiêu đề",
          align: "start",
          sortable: false,
          value: "title"
        },
        {
          text: "Ngày viết",
          align: "start",
          sortable: false,
          value: "created_at"
        },
        {
          text: "Tác giả",
          align: "start",
          sortable: false,
          value: "created_by"
        },
        {
          text: "Lượt xem",
          align: "start",
          sortable: true,
          value: "view_count"
        },
        {
          text: "Trạng thái",
          align: "center",
          sortable: false,
          value: "status"
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "slug"
        }
      ],
      thinkproFrontStoreUrl: process.env.VUE_APP_THINKPRO_FRONT_STORE
    };
  },
  computed: {
    posts() {
      return this.$store.getters["POST/posts"];
    },
    postStatusRequest() {
      return this.$store.getters["POST/statusRequest"];
    }
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  methods: {
    async goToDetailPage(item) {
      await this.$store.dispatch("POST/getPostById", item.id);

      if (this.postStatusRequest.value === "success-getPostById") {
        await this.$router.push({
          name: "website_posts-detail",
          params: {
            postId: item.id
          }
        });
      }
    },

    openNewTab(item) {
      window.open(
        `${this.thinkproFrontStoreUrl}/noi-dung/${item.slug}`,
        "_blank"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  .v-data-table-header {
    background-color: green;
  }
  .copied-label {
    cursor: pointer;
    display: inline-block;
  }
  .view-label {
    cursor: pointer;
    transition: all 0.4s ease;
  }
}
</style>
